body {
    font-family: 'Raleway Regular';
}

.nav-menu-container {
    padding: 40px 0;
    //position: fixed;
    //top: 0;
    width: 100%;
    z-index: 99999;
    background-color: #fff;
    &__login-block {
        display: flex;
        align-items: center;

        a {
            border: 1px solid #e6e6e6;
            border-radius: 20px;
            font-size: 16px;
            color: #60606e;
            padding: 5px 15px;
            display: table;
            margin: 5px 10px 5px 0;
            text-decoration: none;
            outline: none;
        }
    }

    &__logotype {
        display: flex;
        justify-content: center;
        a {
            outline: none;
            img {
                width: 100%;
                max-width: 245px;
            }
        }
    }

    &__search-form {
        form {
          display: flex;
          position: relative;
            input {
                border: 1px solid #e6e6e6;
                border-radius: 20px;
                padding: 5px 30px 5px 15px;
                outline: none;
            }

            button {
                border: 0;
                background: 0 0;
                outline: 0;
                position: relative;
                margin-left: -40px;
                top: 0px;
                cursor: pointer;
                img {

                }
            }
        }

        .search-variations {
            position: absolute;
            top: 100%;
            z-index: 99999;
            border: 1px solid #e6e6e6;
            border-radius: 20px;
            padding:0;
            outline: 0;
            width: 100%;
            max-width: 222px;
            background-color: #fff;
            li {
                list-style: none;
                padding: 5px 30px 5px 15px;
                border-bottom: 1px solid #e6e6e6;
                a {
                    color: #999999;
                    text-decoration: none;
                    outline: none;
                    transition: all .2s ease-in-out;
                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }
}


.nav-menu-list {
    background-color: #ecf0f3;
    //margin-bottom: 50px;
    //position: fixed;
    //top: 125px;
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
    z-index: 999;
}






.slider-container {
    margin-bottom: 30px;
    h2 {
        font-family: 'Fira Sans', sans-serif;
        font-size: 36px;
        color: #60606e;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }
    .left-arrow {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 9999;
        transform: translate(0, -50%);
        left: -30px;
    }

    .right-arrow {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 9999;
        transform: translate(0, -50%);
        right: -30px;
    }

    &__wrapper {
        &__item {
            // width: 100%;
            // max-width: 350px;
            background-color: #f8f9fa;
            outline: none;
            margin: 30px 10px;
            flex-basis: 31%;
            padding: 10px 30px;
            &__head-info {
                display:flex;
                align-items: center;
                justify-content: space-between;
                div {
                    span {
                        font-size: 14px;
                        font-family: 'Raleway Regular';
                        // font-weight: 400;
                        color: #999999;
                        a {

                        }
                    }
                    p {
                        font-size: 14px;
                        font-family: 'Raleway Regular';
                        // font-weight: 400;
                        color: #999999;
                    }
                }

                img {
                    box-shadow: 1px 2px 5px #ccc;
                    border-radius: 50%;
                    position: relative;
                    top: -30px;
                }
            }

            &__content {
                h5 {
                    font-size: 14px;
                    font-family: 'Raleway Bold';
                    // font-weight: 600;
                    color: #666666;
                }

                p {
                    font-size: 14px;
                    font-family: 'Raleway Regular';
                    // font-weight: 100;
                    // font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
}

.title-h2-default {
    font-family: 'Fira Sans',sans-serif;
    font-size: 36px;
    color: #60606e;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}


.leaders-section {
    margin-bottom: 50px;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        &__item {
            background-color: #e7f2ff;            
            flex-basis: 31%;
            margin: 20px 10px;
            width: 100%;
            padding: 10px 30px;
            &__heading {
                display: flex;
                align-items: center;
                &__pro {
                    display: flex;
                    justify-content: flex-start;
                    padding: 0;
                    img {

                    }
                }

                &__logo {
                    display: flex;
                    justify-content: center;
                    img {
                        position: relative;
                        top: -35px;
                        box-shadow: 1px 2px 5px #ccc;
                        border-radius: 50%;
                    }
                }

                &__reviews {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0;
                    span {
                        img {

                        }
                    }
                }
            }

            &__content {
                a {
                    outline: none;
                    h2 {
                        font-family: 'Raleway Bold';
                        font-size: 18px;
                        color: #1675db;
                        // font-weight: 600;
                    }
                }

                p {
                    color: #666666;
                    font-size: 14px;
                    font-family: 'Raleway Regular';
                    // font-weight: 400;
                }
            }
        }
    }
}

.new-companies-container {
    margin-bottom: 50px;
    .left-arrow-new {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 9999;
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        left: -30px;
    }
    

    .right-arrow-new {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 9999;
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        right: -30px;
    }
    

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}

.company-item-block {
    background-color: #f8f9fa;    
    flex-basis: 31%;
    margin: 30px 10px;
    outline: none;
    padding: 10px 30px;
    &__heading {
        display: flex;    
        align-items: center;
        &__left-side {
            div {
                display: inline-flex;
                
                align-items: center;
                .indicator {
                    margin-right: 10px;
                    &.green {
                        background-color: #3ae66b;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
    
                    &.orange {
                        background-color: #efdf46;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
    
                    &.red {
                        background-color: #e2e1e4;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                }
                

               
                    span {
                        font-size: 14px;
                        font-family: 'Raleway Regular';
                    }
                
            }
        }

        &__center-side {
            display: flex;
            justify-content: center;
            img {
                position: relative;
                top: -35px;               
                box-shadow: 1px 2px 5px #ccc;
                border-radius: 50%;
            }
        }

        &__right-side {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
                img {

                }
            }
        }
    }

    &__content {
        a {
            outline: none;
            h2 {
                font-family: 'Raleway Bold';
                font-size: 18px;
                color: #1675db;
                // font-weight: 600;
            }
        }

        p {
            color: #666;
            font-size: 14px;
            font-family: 'Raleway Regular';
            // font-weight: 400;
        }
    }
}

.companies-rating-container {
    margin-bottom: 50px;
    &__nav-tab-style {
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        li {
            margin: 5px 10px;
            a {
                font-family: 'Raleway Regular';
                font-size: 18px;
                color: #000;
                background-color: #f8f9fa;
                border-radius: 10px !important;
                transition: all .2s ease-in-out;
                padding: 20px 35px !important;
                display: block;
                outline: none;
                cursor: pointer;
                text-decoration: none;
                text-align: center;
                border: 0 !important;
                &:hover {
                    border: 0;
                    background-color: #1675db !important;
                    color: #fff !important;
                }
            }
            a.active {
            padding: 20px 35px !important;
            display: block;
            border: 0;
            font-family: 'Raleway Regular';
            font-size: 18px;
            outline: none;
            color: #fff !important;
            background-color: #1675db !important;
            text-decoration: none;
            text-align: center;
            }
        }
    }
}



.categories-slider-container {
    margin-bottom: 50px;
}

.companies-slider {
    .left-arrow-companies {
        cursor: pointer;
        position: absolute;
        top: 37%;
        z-index: 9999;
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        left: -30px;
    }

    .right-arrow-companies {
        cursor: pointer;
        position: absolute;
        top: 37%;
        z-index: 9999;
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        right: -30px;
    }

    &__wrapper {
        &__item {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            position: relative;
            outline: none;
            &__counter {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 1px 2px 5px #ccc;
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 10px;
                font-family: 'Raleway Bold';
                font-size: 16px;
                color: #1675db;
            }

            a {
                outline: none;
                img {
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
           

            &__title {
                a {
                    font-family: 'Raleway Bold';
                    font-size: 16px;
                    color: #1675db;
                    outline: none;
                    display: block;
                    text-decoration: none;
                    margin-top: 20px;
                }
              
            }
        }
    }
}


.home-content-review {
    background-color: #f8f9fa;
    padding: 30px 0;
    &__block {
        margin-bottom: 35px;
        p {
            font-family: 'Raleway Regular';
            // font-weight: 400;
            font-size: 18px;
            color: #666666;
        }

        strong {
            color: #60606e;
            font-size: 24px;
            font-family: 'Fira Sans', sans-serif;
            font-weight: 600;
        }
    }
}


.footer-container {
    background-color: #ecf0f3;
    padding: 35px 0;
    &__menu-footer {
        border-bottom: 2px solid #ced5da;
        ul {
            padding-left: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                list-style: none;
                margin: 5px 15px;
                a {
                    font-family: 'Raleway Regular';
                    // font-weight: 400;
                    font-size: 18px;
                    text-decoration: none;
                    color: #60606e;
                    outline: none;

                }
            }
        }
    }

    &__footer-nav {
        border-bottom: 2px solid #ced5da;
        padding: 30px 0 20px;
        ul {
            padding-left: 0;
            li {
                list-style: none;
                a {
                    font-size: 16px;
                    color: #60606e;
                    font-family: 'Raleway Regular';
                    // font-weight: 400;
                    text-decoration: none;
                    outline: none;
                }
            }
        }
    }

    &__copyright {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
        a {
            img {

            }
        }

        span {
            font-size: 14px;
            color: #999999;
            font-family: 'Raleway Regular';
            text-align: center;
            margin-bottom: 0;
        }
    }
}

.nav-item a img {
    max-width: 17px;
}


.preview {
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    -o-object-fit: contain;
    background-color: #fafafa;
    object-fit: contain;
    cursor: pointer;
    
    
  }
  
  #upload {
    display : none;
    top: 1%;
  }
  
  .upload-image {
    display: block;
  }

  .corner-photo-upload {
    border-radius: 50%;
    display: table;
    position: relative;
    overflow: hidden;
    border: 1px solid #f0f0f0;
  }

  #addcompany {
      padding-right: 0;
      h2 {
        font-size: 24px;
        color: #60606e;
        font-family: 'Fira Sans', sans-serif;
            font-weight: 600;
            display: table;
            margin: 0 auto;
      }
      .modal-dialog {
        max-width: 450px;
          .modal-content {
              border-radius: 15px;
              .modal-body {
                padding: 30px;
                  form {
                      .fullw-style-input {
                        font-family: Raleway,sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        color: #999999;
                        border-radius: 20px;
                        padding: 5px 15px;
                        border: 1px solid #ccc;
                        outline: none;
                        width: 100%;
                        resize: none;
                        margin-bottom: 10px;
                      }

                      .send-btn-blue {
                            background-color: #1358c8;
                            color: #fff;
                            border-radius: 20px;
                            display: table;
                            padding: 10px 25px;
                            margin: 15px auto 0;
                            font-family: Raleway,sans-serif;
                            font-weight: 400;
                            font-size: 18px;
                            border: none;
                            outline: none;
                            cursor: pointer;
                        }

                      fieldset {
                        min-width: 0;
                        padding: 0;
                        margin: 0;
                        border: 0;
                    }
                    
                    .star-cb-group {
                        font-size: 0;
                        unicode-bidi: bidi-override;
                        direction: rtl;
                    }
                    
                    .star-cb-group > input {
                        display: none;
                    }
                    
                    .star-cb-group > input + label {
                        display: inline-block;
                        overflow: hidden;
                        text-indent: -9999px;
                        width: 35px;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                    
                    .star-cb-group > input + label:before {
                        width: 25px;
                        height: 25px;
                        background-color: #e2e1e4;
                        border-radius: 50%;
                        margin: 5px;
                        display: inline-block;
                        content: "";
                    }
                    
                    .star-cb-group * {
                        font-size: 1.5rem;
                    }







                    
                    .star-cb-group > #rating-1:checked ~ label:before, .star-cb-group > #rating-1 + label:hover ~ label:before, .star-cb-group > #rating-1 + label:hover:before {
                        background-color: red;
                    }
                    .star-cb-group > #rating-2:checked ~ label:before, .star-cb-group > #rating-2 + label:hover ~ label:before, .star-cb-group > #rating-2 + label:hover:before {
                        background-color: red;
                    }
                    .star-cb-group > #rating-3:checked ~ label:before, .star-cb-group > #rating-3 + label:hover ~ label:before, .star-cb-group > #rating-3 + label:hover:before {
                        background-color: red;
                    }
                    .star-cb-group > #rating-4:checked ~ label:before, .star-cb-group > #rating-4 + label:hover ~ label:before, .star-cb-group > #rating-4 + label:hover:before {
                        background-color: #ffcc3a;
                    }
                    .star-cb-group > #rating-5:checked ~ label:before, .star-cb-group > #rating-5 + label:hover ~ label:before, .star-cb-group > #rating-5 + label:hover:before {
                        background-color: #ffcc3a;
                    }
                    .star-cb-group > #rating-6:checked ~ label:before, .star-cb-group > #rating-6 + label:hover ~ label:before, .star-cb-group > #rating-6 + label:hover:before {
                        background-color: #ffcc3a;
                    }
                    .star-cb-group > #rating-7:checked ~ label:before, .star-cb-group > #rating-7 + label:hover ~ label:before, .star-cb-group > #rating-7 + label:hover:before {
                        background-color: #ffcc3a;
                    }
                    .star-cb-group > #rating-8:checked ~ label:before, .star-cb-group > #rating-8 + label:hover ~ label:before, .star-cb-group > #rating-8 + label:hover:before {
                        background-color: #04e675;
                    }
                    .star-cb-group > #rating-9:checked ~ label:before, .star-cb-group > #rating-9 + label:hover ~ label:before, .star-cb-group > #rating-9 + label:hover:before {
                        background-color: #04e675;
                    }
                    .star-cb-group > #rating-10:checked ~ label:before, .star-cb-group > #rating-10 + label:hover ~ label:before, .star-cb-group > #rating-10 + label:hover:before {
                        background-color: #04e675;
                    }
                      
                  }
              }
          }
      }
  }
  .modal-header .close {
    padding: 1rem;
    margin: -15px 0px 0 -35px;
    outline: none;
}

  #addreview {
    padding-right: 0;
    h2 {
        font-size: 24px;
        color: #60606e;
        display: table;
            margin: 0 auto;
        font-family: 'Fira Sans', sans-serif;
            font-weight: 600;
    }
    .modal-dialog {
      max-width: 450px;
        .modal-content {
            border-radius: 15px;
            .modal-body {
              padding: 30px;
                form {
                    .fullw-style-input {
                      font-family: Raleway,sans-serif;
                      font-weight: 400;
                      font-size: 16px;
                      color: #999999;
                      border-radius: 20px;
                      padding: 5px 15px;
                      border: 1px solid #ccc;
                      outline: none;
                      width: 100%;
                      resize: none;
                      margin-bottom: 10px;
                    }

                    .send-btn-blue {
                          background-color: #1358c8;
                          color: #fff;
                          border-radius: 20px;
                          display: table;
                          padding: 10px 25px;
                          margin: 15px auto 0;
                          font-family: Raleway,sans-serif;
                          font-weight: 400;
                          font-size: 18px;
                          border: none;
                          outline: none;
                          cursor: pointer;
                      }

                    fieldset {
                      min-width: 0;
                      padding: 0;
                      margin: 0;
                      border: 0;
                  }
                  
                  .star-cb-group {
                      font-size: 0;
                      unicode-bidi: bidi-override;
                      direction: rtl;
                  }
                  
                  .star-cb-group > input {
                      display: none;
                  }
                  
                  .star-cb-group > input + label {
                      display: inline-block;
                      overflow: hidden;
                      text-indent: -9999px;
                      width: 35px;
                      white-space: nowrap;
                      cursor: pointer;
                  }
                  
                  .star-cb-group > input + label:before {
                      width: 25px;
                      height: 25px;
                      background-color: #e2e1e4;
                      border-radius: 50%;
                      margin: 5px;
                      display: inline-block;
                      content: "";
                  }
                  
                  .star-cb-group * {
                      font-size: 1.5rem;
                  }
                  
                  .star-cb-group > #rat-1:checked ~ label:before, .star-cb-group > #rat-1 + label:hover ~ label:before, .star-cb-group > #rat-1 + label:hover:before {
                    background-color: red;
                }
                .star-cb-group > #rat-2:checked ~ label:before, .star-cb-group > #rat-2 + label:hover ~ label:before, .star-cb-group > #rat-2 + label:hover:before {
                    background-color: red;
                }
                .star-cb-group > #rat-3:checked ~ label:before, .star-cb-group > #rat-3 + label:hover ~ label:before, .star-cb-group > #rat-3 + label:hover:before {
                    background-color: red;
                }
                .star-cb-group > #rat-4:checked ~ label:before, .star-cb-group > #rat-4 + label:hover ~ label:before, .star-cb-group > #rat-4 + label:hover:before {
                    background-color: #ffcc3a;
                }
                .star-cb-group > #rat-5:checked ~ label:before, .star-cb-group > #rat-5 + label:hover ~ label:before, .star-cb-group > #rat-5 + label:hover:before {
                    background-color: #ffcc3a;
                }
                .star-cb-group > #rat-6:checked ~ label:before, .star-cb-group > #rat-6 + label:hover ~ label:before, .star-cb-group > #rat-6 + label:hover:before {
                    background-color: #ffcc3a;
                }
                .star-cb-group > #rat-7:checked ~ label:before, .star-cb-group > #rat-7 + label:hover ~ label:before, .star-cb-group > #rat-7 + label:hover:before {
                    background-color: #ffcc3a;
                }
                .star-cb-group > #rat-8:checked ~ label:before, .star-cb-group > #rat-8 + label:hover ~ label:before, .star-cb-group > #rat-8 + label:hover:before {
                    background-color: #04e675;
                }
                .star-cb-group > #rat-9:checked ~ label:before, .star-cb-group > #rat-9 + label:hover ~ label:before, .star-cb-group > #rat-9 + label:hover:before {
                    background-color: #04e675;
                }
                .star-cb-group > #rat-10:checked ~ label:before, .star-cb-group > #rat-10 + label:hover ~ label:before, .star-cb-group > #rat-10 + label:hover:before {
                    background-color: #04e675;
                }
                    
                }
            }
        }
    }
}
  .modal-backdrop {
      background-color: #1675db;
      opacity: .6;
  }

  .company-item-block__heading__right-side {
    span {
        display: flex;
        align-items: center;
        img {
            margin-left: 10px;
        }
    }
}

.pro-acc-content {
    padding: 50px 0;
    &__wrapper {
        &__item {
            padding: 40px;
            background-color: #f8f9fa;
            margin: 20px 0;
            h2 {
                margin-bottom: 30px;
                font-size: 28px;
            }

            p {
                font-family: 'Raleway Regular';
                // font-weight: 400;
                font-size: 18px;
                color: #666666;
            }

            .buy-pro-btn {
                background-color: #1673da;
                color: #fff;
                text-align: center;
                font-family: 'Raleway Regular';
                padding: 10px 25px;
                font-size: 16px;
                border-radius: 50px;
                text-decoration: none;
                outline: none;
                
            }
        }
    }
}

.maxw-800 {
    max-width: 800px;
    width: 100%;
}

.companies-catalog-container {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}

.pagination-container {
    margin: 20px 0;
    ul {
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            list-style: none;
            a {
                font-family: 'Raleway Bold';
                background-color: #f8f9fa;
                border-radius: 50%;
                padding: 5px 10px;
                font-size: 18px;
                color: #1675db;
                align-items: center;
                display: flex;
                margin: 3px;
                line-height: 20px;
                justify-content: center;
                outline: 0;
                text-decoration: none;
                &:hover {
                    color: #000;
                }
                &.active {
                    color: #000;
                }
            }
        }
    }
}

.left-arrow-news {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 9999;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    left: -30px;
}


.right-arrow-news {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 9999;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    right: -30px;
}

.news-slider-container {
    margin-top: 60px;
    &__wrapper {
        margin: 20px 0 50px;
        &__item {
            padding: 10px;
            a {
                outline: none;
                text-decoration: none;
                h2 {
                    font-family: 'Raleway Bold';
                    font-size: 18px;
                    color: #1675db;
                    margin: 20px 0 15px;
                }

                img {
                    width: 100%;
                    border-radius: 15px;
                }   
            }

            p {
                font-size: 16px;
                color: #666;
                font-family: 'Raleway Regular';
            }
        }
    }
}

.slick-slide {
    outline: none;
}

.breadcrumb {
    background-color: #f8f9fa !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '→';
    color: #999999;
}

.breadcrumb-item a {
    color: #1675db;
    font-size: 14px;
    font-family: 'Raleway Regular';
    text-decoration: none;

}

.breadcrumb-item:last-child a {
    color: #999999;
   
}

.single-content-company {
    &__description {
        background-color: #f8f9fa;
        padding: 25px;
        &__left-side {
            h2 {
                margin-bottom: 30px;
            }
        }

        &__right-side {
            img {
                width: 100%;
                max-width: 105px;
                top: -30px;
                position: relative;
                border-radius: 50%;
                box-shadow: 0 0 5px #ccc;
            }
        }

        p {
            font-family: 'Raleway Regular';
            font-size: 14px;
            color: #666;
            strong {

            }

            a {
                font-family: 'Raleway Bold';
                color: #1675db;
                outline: none;
                text-decoration: none;
            }

            
        }
    }

    &__reviews-statistic {
        margin-top: 20px;
        padding: 25px;
        .reviews-count-title {
            font-family: 'Fira Sans',sans-serif;
            font-size: 36px;
            color: #60606e;
            font-weight: 600;
            text-align: center;
        }
    }
    
    &__reviews-container {
        &__item {
            background-color: #f8f9fa;
            padding: 20px;
            margin: 10px 0;
            &__navigation {
                display: flex;
                justify-content: space-between;
                .left-side {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    span {
                        color: #999999;
                        font-size: 14px;
                        font-family: Raleway,sans-serif;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        &:after {
                            content: '→';
                            display: block;
                            margin: 0 10px;
                        }
                    }

                    p {
                        color: #999999;
                        font-size: 14px;
                        font-family: Raleway,sans-serif;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                }

                .right-side {
                    display: flex;
                    align-items: center;
                    p {
                        display: flex;
                        align-items: center;
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }

            h4 {
                font-size: 14px;
                font-family: 'Raleway Bold';
                color: #666666;
            }

            p {
                font-size: 14px;
                font-family: 'Raleway Regular';
                color: #666666;
            }
        }
    }
}

.buy-pro-btn {
    background-color: #1673da;
    color: #fff;
    text-align: center;
    font-family: 'Raleway Regular';
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 50px;
    text-decoration: none;
    outline: 0;
    &:hover {
        color: #fff;
        text-decoration: none;
        outline: none;

    }
}




.progress-wrapper {
    width: 100%;
    // max-width: 800px;
    height: 10px;
    background-color: #ccc;
    margin: 20px auto 25px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    span {
      height: 10px;
      
    }
    .positive {   
      background-color: #00e676;
      display: block;    
    }
    
    .negative {
      background-color: #ff1744;
      display: block;
    }
    
    .neutral {
      background-color: #ffd740;
      display: block;
    }
  }
  
  .green-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00e676;  
    display: block;
    
  }
  
  .red-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff1744;
    display: block;
  }
  
  .orange-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffd740;
    display: flex;
  }
  
  .positive-counter, .negative-counter, .neutral-counter {
    display: flex;
    align-items: center;
    position: relative;  
    margin-right: 40px;
    padding-left: 20px;
    span {
     position: absolute;
      left: 0;   
    }
    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }


.reviews-block-container {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        .slider-container__wrapper__item {
            flex-basis: 32.33%;
            margin: 25px 5px;
        }
    }
}



.profile-picture-upload {
    display: flex;
    flex-direction: column; 
    align-items: center;
  }
  
  .imagePreview {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto 20px;
    -webkit-box-shadow: 0 3px 10px 2px rgba(0,0,0,.35);
    box-shadow: 0 3px 10px 2px rgba(0,0,0,.35);
}
  
  
  .hidden {
    position: absolute;
    width: 0px;
    height: 0px;
    left: -999999px;
  }
  
  .action-button {
    border: 1px solid black;
    background: none;
    padding: 8px 45px;
    cursor: pointer;
    outline: 0;
    border-radius: 6px;
    
    &.mode-upload {
        color: #000;
        border-color: #ccc;
        background-color: #fff;
        border-radius: 20px;
        width: auto;
        display: table;
        position: relative;
        outline: none;
       
        &:before {
            content: '';
            width: 18px;
            display: block;
            position: absolute;
            left: 15px;
            top: 28%;
            height: 18px;
            background-repeat: no-repeat;
            background-image: url(/assets/img/upload-ico.png);
        }
    }
    
    &.mode-remove {
        color: #000;
        border-color: #ccc;
        background-color: #fff;
        border-radius: 20px;
        width: auto;
        display: table;
        outline: none;
        position: relative;
        &:before {
            content: '';
            width: 18px;
            display: block;
            position: absolute;
            left: 15px;
            top: 28%;
            background-repeat: no-repeat;
            height: 18px;
            background-image: url(/assets/img/delete-ico.png);
        }
    }
  }

  .gray-bg-style {
    background-color: #f8f9fa;
    padding: 45px;
    display: flex;
    flex-direction: column;   
    height: 100%;
   
    align-items: flex-start;
    justify-content: flex-start;
  }


.profile-info-container {
    margin-bottom: 50px;
    &__wrapper {
        &__item {
            padding: 0px 5px;
           &:first-child {
               .gray-bg-style {
                align-items: center;
                justify-content: center;
               }
           }
            h5 {
                font-family: Raleway,sans-serif;
                font-weight: 600;
                color: #666666;
                font-size: 14px;
            }

            p {
                font-family: 'Raleway Regular';
                color: #666666;
                margin-bottom: 7px;
                font-size: 14px;
                strong {
                    font-weight: 600;
                }

                a {
                    color: #666;
                    text-decoration: underline;
                }
            }

            .buy-pro-btn {
                margin: 15px 0;
                font-size: 18px;
                padding: 10px 15px;
                width: 100%;
            }

            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                input {
                   color: #999999;
                   font-size: 16px;
                   padding: 5px 15px;
                   width: 100%;
                   border-radius: 20px;
                   border: 1px solid #ccc;
                   outline: none; 
                   margin-bottom: 10px;
                }
    
                button {
                    color: #60606e;
                    font-size: 16px;
                    padding: 5px 15px;
                    width: auto;
                    display: table;
                    border-radius: 20px;
                    border: 1px solid #ccc;
                    outline: none; 
                    cursor: pointer;
                    margin-bottom: 10px;
                    background-color: #fff;
                }
            }
        }
    }
}



/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 10px 0;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	padding: 5px 0;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
	height: 28px;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navigation-wrap {
	
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar {
	padding: 0;
}
.navbar-brand img {
	height: 52px;
	width: auto;
	display: block;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar-toggler {
	float: right;
	border: none;
	padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
	outline: none;
}
.navbar-light .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 1px solid #000;
    transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after, 
.navbar-light .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 1px;
	background-color: #000;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
    transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
	top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
.nav-link {
	color: #212121 !important;
	// font-weight: 500;
    transition: all 200ms linear;
}

.nav-link {
	position: relative;
	// padding: 5px 0 !important;
    display: inline-block;
    font-family: 'Raleway Bold';
}
.nav-item:after {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 2px;
	content: '';

	opacity: 0;
    transition: all 200ms linear;
}
.nav-item:hover:after {
	bottom: 0;
	opacity: 1;
}
.nav-item.active:hover:after {
	opacity: 0;
}
.nav-item {
	position: relative;
    transition: all 200ms linear;
    padding: 10px 0;
}

.navigation-wrap {
    padding: 0;
}

/* #Primary style
================================================== */


.nav-item .dropdown-menu {
    transform: translate3d(0, 0px, 0);
    visibility: hidden;
    opacity: 0;
	max-height: 0;
    display: block;
	padding: 0;
	margin: 0;
    transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
	max-height: 999px;
    transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
	padding: 10px!important;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: #212121;
	background-color: #ecf0f3;
	border: none;
    transition: all 200ms linear;
}
.dropdown-toggle::after {
	display: none;
}
.dropdown-item {
    padding: 5px 15px;
    color: #212121;
    font-family: 'Raleway Regular' !important;
    transition: all 200ms linear;
}

/* #Media
================================================== */

@media (max-width: 991px) { 

    .navigation-wrap {
        padding: 10px 0 10px;
    }

    .navbar-nav .nav-item {
        padding: 3px 10px 3px 10px;
    }

    .navbar-nav .nav-item a {
        width: 100%;
        text-align: center;
        position: relative;
        font-family: 'Raleway Bold';
       
    }
	
	.nav-item:after {
		display: none;
	}
	.nav-item::before {
        position: absolute;
        display: block;
        top: 29px;
        left: 10px;
        // width: 11px;
        // height: 1px;
        content: "";
        border: none;
        background-color: #000;
        vertical-align: 0;
	}
	.dropdown-toggle::after {
        position: absolute;
        display: block;
        top: 13px;
        left: 0;
        width: 30px;
        height: 15px;
        content: url(../img/more.png);
        border: none;
        // background-color: #000;
        vertical-align: 0;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
	}
	.dropdown-toggle[aria-expanded="true"]::after {
        transform: rotate(180deg);
        opacity: 1;
	}
	.dropdown-menu {
		padding: 0 !important;
		background-color: transparent;
		box-shadow: none;
		transition: all 200ms linear;
	}
	.dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
	}
}

#heading{
    font-family: 'Creepster', cursive;
    font-size: 3em;
    text-align: center;
  }
  
  
  .eyes{
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background-color: white;
    margin: 20px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: blinking 3s ease-out 2 5s;
    
  }
  
  #eye-container{  
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .pupils{
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    background-color: #1675db;
    animation: moving-pups 2.5s cubic-bezier(.43,-0.45,.45,1.42) infinite;
  }

  .gohome {
      padding: 10px 20px;
      background-color: #1675db;
      color: #fff;
      text-decoration: none;
      outline: none;
      display: table;
        margin: 0 auto;
        position: relative;
        &:hover {
            color: #fff;
            text-decoration:none;
        }
  }
  
  @keyframes moving-pups{
    0% {left: 14%;}
    50%{left: 70%; }
    100% {left: 14%;}  
  }
  
  @keyframes blinking{
    0%{transform: rotateX(0deg);}
    20%{transform: rotateX(90deg);}
    60%{transform: rotateX(45deg);}
    70%{transform: rotateX(30deg);}
    80%{transform: rotateX(0deg);}
    100%{transform: rotateX(0deg);}
  }

  .error-container {
      padding: 30px 0 100px;
      h1 {
        font-size: 251px;
        color: #60606e;
        font-family: 'Fira Sans',sans-serif;
        font-weight: 600;
      }

      span {
          text-align: center;
          font-size: 16px;
          color: #60606e;
          a {
            color: #60606e;
            text-decoration: none;
          }
      }
  }

.modal {
    z-index: 99999999;
}

.mt70px {
    margin-top: 70px;
    position: relative;
}


  @media screen and (max-width: 1200px) {
    .nav-menu-container__search-form form {
        display: flex;        
    }

    .navbar-nav .nav-item {
       
        a {
            font-size: 14px;
        }
    }
    .company-item-block__heading__left-side div p {
        display: flex;
    }

    .footer-container__menu-footer ul {
        margin-bottom: 0;
        li {
            margin: 5px 10px;
            a {
                font-size: 16px;
            }
        }
    }

    .slider-container__wrapper__item {
        background-color: #f8f9fa;
        outline: 0;
        margin: 30px 5px;
        padding: 10px 20px;
    }

    .reviews-block-container__wrapper .slider-container__wrapper__item {
        margin: 25px 4px;
    }

    .slider-container .left-arrow, .new-companies-container .left-arrow-new, .companies-slider .left-arrow-companies {
        left: -25px;
    }

    .slider-container .right-arrow, .new-companies-container .right-arrow-new, .companies-slider .right-arrow-companies {
        right: -25px;
    }

    .leaders-section__wrapper__item {
        padding: 10px 20px;
    }

    .company-item-block {
        margin: 30px 5px;
        padding: 10px 20px;
    }

    .companies-slider__wrapper__item__counter {
        right: 13%;
    }  
  }

  @media screen and (max-width: 991px) {

    .mt70px {
        margin-top: 45px;
        position: relative;
    }



    .nav-menu-container {
        padding: 0 0;
        position: relative;
        background-color: #fff;
        width: 100%;
        top: 0;
        z-index: 999999999;
    }

    .nav-menu-list {
        margin-bottom: 30px;
        z-index: 999999;
        position: relative;
        top: 56px;
        width: 100%;
    }

    .reviews-block-container__wrapper .slider-container__wrapper__item {
        margin: 25px 5px;
        flex-basis: 48%;
    }
    .nav-menu-container__logotype a img {
        max-width: 190px;
    }

    .nav-menu-container__search-form form input {
        width: 100%;
    }

    .nav-menu-list {
        padding:  0;
        .navbar {
            justify-content: center;
            align-items: center;
            .navbar-toggler {
                p {
                    margin-bottom: 0;
                    margin-left: 15px;
                }
            }
        }
    }

    .nav-menu-container {
        padding: 0px 0;
    }

    .nav-menu-list {
        margin-bottom: 30px;
    }

    .title-h2-default {
        margin-bottom: 35px !important;
    }
    .leaders-section__wrapper__item {
        flex-basis: 47%;
    }

    .navbar-nav .nav-item {
        display: block;   
        justify-content: center;
    }

    .leaders-section, .new-companies-container, .companies-rating-container {
        margin-bottom: 35px;
    }

    .companies-rating-container__nav-tab-style {
        margin-bottom: 35px;
        li {
            a {
                padding: 10px 15px !important;
            }

            a.active {
                padding: 10px 15px !important;
            }
        } 
    }     

    .company-item-block {
        flex-basis: 48%;
    }

    .footer-container__menu-footer ul {
        justify-content: center;
    }

    .footer-container {
        padding: 15px 0;
    }


    .nav-menu-container__logotype {
        margin: 10px 0;
    }

    .nav-menu-container__search-form, .nav-menu-container__login-block {
        justify-content: center;
    }

    .left-arrow, .right-arrow {
        display: none;
    }

    .leaders-section__wrapper__item {
        flex-basis: 100%;
        margin: 20px 0;
    }
  }
    .mob-style-block {
        display: none !important;
    }

@media screen and (max-width: 768px) {

    .mob-style-block {
        display: block !important;
    }

    .mob-style-none {
        display: none !important;
    }
    .mt70px {
        margin-top: 0;
        position: relative;
    }

    .nav-menu-container {
        padding: 0 0;
        position: relative;
        background-color: #fff;
        z-index: 999999;
        width: 100%;
    }



    .nav-menu-list {
        margin-bottom: 30px;
        position: relative;
        top: 0;
        z-index: 99999;
        width: 100%;
    }

    .company-item-block {
        flex-basis: 100%;
    }

    .reviews-block-container__wrapper .slider-container__wrapper__item {
        margin: 25px 0px;
        flex-basis: 100%;
    }

    .left-arrow-news, .right-arrow-news {
        display: none;
    }

    .news-slider-container__wrapper__item {
        padding: 0;
    }

    .pro-acc-content__wrapper__item {
        padding: 15px;
    }

    .title-h2-default {
        font-size: 30px;
    }
}

@media screen and (max-width: 580px) {
    .single-content-company__description__left-side h2 {
        margin-top: 15px;
    }
    
    .single-content-company__description__right-side {
        display: flex;
        justify-content: center;
    }
    .new-companies-container .right-arrow-new, .new-companies-container .left-arrow-new, .companies-slider .right-arrow-companies, .companies-slider .left-arrow-companies {
        display: none;
    }

    .single-content-company__reviews-container__item__navigation .right-side {
        margin-bottom: 15px;
    }

    .flex-style580 {
        display: block !important;
    }

    .single-content-company__description {
        padding: 15px;
    }

    .single-content-company__reviews-container__item__navigation .left-side {
        margin-bottom: 0;
    }

    .single-content-company__reviews-container__item__navigation {
        display: block;
    }

    .buy-pro-btn {
        padding: 7px 15px;
        font-size: 14px;
    }

    .single-content-company__reviews-statistic .reviews-count-title {
        font-size: 24px;
    }

    .single-content-company__description__right-side img {
        top: 0;
    }

    .single-content-company__reviews-statistic .reviews-count-title {
        text-align: left;
    }

    .flex-column-style {
        flex-direction: column-reverse;
    }

    
}

@media screen and (max-width: 480px) {

    .error-container h1 {
        font-size: 150px;
    }

    .error-container {
        padding: 0px 0 80px;
    }

    .nav-menu-list .navbar .navbar-toggler p {
        display: none;
    }

    .profile-info-container__wrapper__item .gray-bg-style {
        padding: 10px;
    }
    

    .title-h2-default {
        font-size: 24px !important; 
    }

    

    .nav-menu-container__login-block {
        justify-content: center;
    }


}

.nav-item.active {
    background-color: #fff;
}

.loadmore-btn {
    font-family: 'Raleway Bold';
    font-size: 16px;
    color: #1675db;
    display: flex;
    flex-direction: column;
    outline: none;
    text-decoration: none;
    align-items: center;    
    img {

    }
}

.leaders-section__wrapper__item__heading__reviews span {
    color: #666;
    display: flex;
    align-items: center;
    img {
        margin-left: 5px;
    }
}


.header-scroll-start {
    opacity: 1;
    padding: 0;
    position: fixed;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin: 0;
}


#rating-5.active-style ~ label:before, #rat-5.active-style ~ label:before {
    background-color: #ffcc3a !important;
}


.breadcrums-container {
    position: relative;

}