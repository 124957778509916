/* Variables */
$fontPath: 'assets/fonts/';

/* Font faces */
@font-face {
  font-family: 'font-name';
  src: url('assets/fonts/font-name.font-extension') format('format');
}



@font-face {
  font-family: 'Raleway Black';
  src: url('../fonts/Raleway/Black/raleway-black.woff2') format('woff2'), 
  url('../fonts/Raleway/Black/raleway-black.woff') format('woff'), 
  url('../fonts/Raleway/Black/raleway-black.ttf') format('truetype');
  // font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Black-italic';
  src: url('../fonts/Raleway/Black-italic/raleway-black-italic.woff2') format('woff2'), 
  url('../fonts/Raleway/Black-italic/raleway-black-italic.woff') format('woff'), 
  url('../fonts/Raleway/Black-italic/raleway-black-italic.ttf') format('truetype');
  // font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway Bold';
  src: url('../fonts/Raleway/Bold/raleway-bold.woff2') format('woff2'), 
  url('../fonts/Raleway/Bold/raleway-bold.woff') format('woff'), 
  url('../fonts/Raleway/Bold/raleway-bold.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Extra-Bold';
  src: url('../fonts/Raleway/Extra-bold/raleway-extrabold.woff2') format('woff2'), 
  url('../fonts/Raleway/Extra-bold/raleway-extrabold.woff') format('woff'), 
  url('../fonts/Raleway/Extra-bold/raleway-extrabold.ttf') format('truetype');
  // font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Extra-light';
  src: url('../fonts/Raleway/Extra-light/raleway-extralight.woff2') format('woff2'), 
  url('../fonts/Raleway/Extra-light/raleway-extralight.woff') format('woff'), 
  url('../fonts/Raleway/Extra-light/raleway-extralight.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Light';
  src: url('../fonts/Raleway/Light/raleway-light.woff2') format('woff2'), 
  url('../fonts/Raleway/Light/raleway-light.woff') format('woff'), 
  url('../fonts/Raleway/Light/raleway-light.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Medium';
  src: url('../fonts/Raleway/Medium/raleway-medium.woff2') format('woff2'), 
  url('../fonts/Raleway/Medium/raleway-medium.woff') format('woff'), 
  url('../fonts/Raleway/Medium/raleway-medium.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Regular';
  src: url('../fonts/Raleway/Regular/raleway-regular.woff2') format('woff2'), 
  url('../fonts/Raleway/Regular/raleway-regular.woff') format('woff'), 
  url('../fonts/Raleway/Regular/raleway-regular.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Semibold';
  src: url('../fonts/Raleway/Semibold/raleway-semibold.woff2') format('woff2'), 
  url('../fonts/Raleway/Semibold/raleway-semibold.woff') format('woff'), 
  url('../fonts/Raleway/Semibold/raleway-semibold.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Thin';
  src: url('../fonts/Raleway/Thin/raleway-thin.woff2') format('woff2'), 
  url('../fonts/Raleway/Thin/raleway-thin.woff') format('woff'), 
  url('../fonts/Raleway/Thin/raleway-thin.ttf') format('truetype');
  // font-weight: 600;
  font-style: normal;
}
